import { createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
import InteractivityLayout from "./app/(protected)/interactivity/layout";
import SinglePlaylistLayout from "./app/(protected)/interactivity/[id]/layout";
import ThemeLayout from "./app/(protected)/theme_manager/layout";
// import EditPlaylistPage from "";
import { ulid } from "ulid";


const EditPlaylistPage = lazy(
  () => import("./app/(protected)/interactivity/[id]/edit/page")
);
const CreatePlaylistPage = lazy(
  () => import("./app/(protected)/interactivity/create/page")
);
const AuthLayout = lazy(() => import("./app/(protected)/layout"));
const ChatManagerPage = lazy(
  () => import("./app/(protected)/chat_manager/page")
);
const PlayoutManager = lazy(
  () => import("./app/(protected)/playout_manager/page")
);
const PlayoutManagerTab = lazy(
  () => import("./app/(protected)/playout_manager/tab_page")
);
const PlayoutManagerTabV2 = lazy(
  () => import("./app/(protected)/playout_manager_v2/tab_page")
);
const PlayoutManagerTabPlay = lazy(
  () => import("./app/(protected)/playout_manager_v2/tab_page_playground")
);
const SettingsLayout = lazy(() => import("./app/(protected)/settings/layout"));
const Settings = lazy(() => import("./app/(protected)/settings/page"));
const BannedWords = lazy(
  () => import("./app/(protected)/settings/banned_words/page")
);
const ChatRules = lazy(
  () => import("./app/(protected)/settings/chat_rules/page")
);
const UnauthLayout = lazy(() => import("./app/(unprotected)/layout"));
const LoginPage = lazy(() => import("./app/(unprotected)/login/page"));
const SetPasswordPage = lazy(
  () => import("./app/(unprotected)/set_password/page")
);
const ForgotPassword = lazy(
  () => import("./app/(unprotected)/forgot_password/page")
);

const BlankAuthLayout = lazy(() => import("./app/(protected)/blank_layout"));
const PlaylistPage = lazy(() => import("./app/(protected)/interactivity/page"));
const SceneIndexPage = lazy(
  () => import("./app/(protected)/interactivity/[id]/page")
);
const CreateInteractivityPage = lazy(
  () => import("./app/(protected)/interactivity/[id]/scene/[id]/create/page")
);
const EditScenePage = lazy(
  () => import("./app/(protected)/interactivity/[id]/scene/[id]/edit/page")
);
const EditInteractivityPage = lazy(
  () =>
    import(
      "./app/(protected)/interactivity/[id]/scene/[id]/interactivity/[id]/edit/page"
    )
);
const ReportsLayout = lazy(() => import("./app/(protected)/reports/layout"));
const Reports = lazy(() => import("./app/(protected)/reports/page"));
const AdvertismentReport = lazy(
  () => import("./app/(protected)/reports/advertisment/page")
);
const AdvertismentDetails = lazy(
  () => import("./app/(protected)/reports/advertisment/[id]/page")
);
const InteractivityReport = lazy(
  () => import("./app/(protected)/reports/interactivity/page")
);
const InstanceDetails = lazy(
  () => import("./app/(protected)/reports/interactivity/[id]/page")
);
const InteractivityDetails = lazy(
  () =>
    import(
      "./app/(protected)/reports/interactivity/[id]/interactivities/[id]/page"
    )
);
const PrizeDisbursementReport = lazy(
  () => import("./app/(protected)/reports/prize_disbursement/page")
);
const PrizeDisbursementDetails = lazy(
  () => import("./app/(protected)/reports/prize_disbursement/[id]/page")
);
const DashboardPage = lazy(() => import("./app/(protected)/dashboard/page"));
const ArchivedPage = lazy(
  () => import("./app/(protected)/interactivity/archived/page")
);
import ErrorBoundary from "./components/ErrorBoundary";
import PageNotFound from "./components/400";
import { marketingManagerRoutes } from "./app/(protected)/marketing_manager/routes";
const UserManagement = lazy(
  () => import("./app/(protected)/settings/user_management/page")
);
const UserManagementLayout = lazy(
  () => import("./app/(protected)/settings/user_management/layout")
);
const UserManagementCreate = lazy(
  () => import("./app/(protected)/settings/user_management/create/page")
);
const UserManagementUpdate = lazy(
  () => import("./app/(protected)/settings/user_management/[id]/page")
);
const ProfilePage = lazy(() => import("./app/(protected)/profile/page"));

const ThemeManagerPage = lazy(
  () => import("./app/(protected)/theme_manager/page")
);
const CreateThemePage = lazy(
  () => import("./app/(protected)/theme_manager/create/page")
);
const UpdateThemePage = lazy(
  () => import("./app/(protected)/theme_manager/[id]/edit/page")
);
const CustomizeThemePage = lazy(
  () => import("./app/(protected)/theme_manager/[id]/customize/page")
);

const ProgramManagementLayout = lazy(
  () => import("./app/(protected)/program_management/layout")
);
const ProgramManagement = lazy(
  () => import("./app/(protected)/program_management/page")
);

const AddProgramManagement = lazy(
  () => import("./app/(protected)/program_management/add/page")
);

const ViewProgramManagement = lazy(
  () => import("./app/(protected)/program_management/[id]/view/page")
);
const UpdateProgramManagement = lazy(
  () => import("./app/(protected)/program_management/[id]/update/page")
);

export const routes = createBrowserRouter([
  {
    element: (
      <ErrorBoundary>
        <UnauthLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "/set-password",
        element: <SetPasswordPage />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      { path: "*", element: <PageNotFound /> },
    ],
  },
  {
    element: (
      <ErrorBoundary>
        <AuthLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      {
        path: "chat_manager",
        element: <ChatManagerPage />,
      },
      {
        path: "interactivity",
        element: <InteractivityLayout />,
        children: [
          {
            index: true,
            element: <PlaylistPage />,
          },
          {
            path: "archived",
            element: <ArchivedPage />,
          },
          {
            path: "create",
            element: <CreatePlaylistPage />,
          },
          {
            path: ":playlistId/edit",
            element: <EditPlaylistPage />,
          },
          {
            path: ":playlistId",
            element: <SinglePlaylistLayout />,
            children: [
              {
                index: true,
                element: <SceneIndexPage />,
              },
              {
                path: "scene/:sceneId",
                element: <SceneIndexPage />,
                children: [
                  {
                    path: "create",
                    element: <CreateInteractivityPage />,
                  },
                  {
                    path: "edit",
                    element: <EditScenePage />,
                  },
                  {
                    path: "interactivity/:interactivityId/edit",
                    element: <EditInteractivityPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "playout_manager",
        element: <PlayoutManager />,
      },
      {
        path: "theme_manager",
        element: <ThemeLayout />,
        children: [
          {
            index: true,
            element: <ThemeManagerPage />,
          },
          {
            path: "create",
            element: <CreateThemePage />,
          },
          {
            path: ":themeId/edit",
            element: <UpdateThemePage />,
          },
          {
            path: ":themeId/customize",
            element: <CustomizeThemePage />,
          },
        ],
      },
      {
        path: "settings",
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            element: <BannedWords />,
          },
          {
            path: "users",
            element: <UserManagementLayout />,
            children: [
              {
                index: true,
                element: <UserManagement />,
              },
              {
                path: "create",
                element: <UserManagementCreate />,
              },
              {
                path: ":userId/edit",
                element: <UserManagementUpdate />,
              },
            ],
          },
          {
            path: "banned-word",
            element: <BannedWords />,
          },
          {
            path: "chat",
            element: <ChatRules />,
          },
        ],
      },
      {
        path: "program-management",
        element: <ProgramManagementLayout />,
        children: [
          {
            index: true,
            element: <ProgramManagement />,
          },
          {
            path: ":programId/view",
            element: <ViewProgramManagement />,
          },
          {
            path: ":programId/update",
            element: <UpdateProgramManagement />,
          },
          {
            path: "create",
            element: <AddProgramManagement />,
          },
        ],
      },
      {
        path: "reports",
        element: <ReportsLayout />,
        children: [
          {
            index: true,
            element: <Reports />,
          },
          {
            path: "advertisment",
            element: <BlankAuthLayout />,
            children: [
              {
                index: true,
                element: <AdvertismentReport />,
              },
              {
                path: ":adsId",
                element: <AdvertismentDetails />,
              },
            ],
          },
          {
            path: "interactivity",
            element: <BlankAuthLayout />,
            children: [
              {
                index: true,
                element: <InteractivityReport />,
              },
              {
                path: ":instanceId",
                element: <InstanceDetails />,
              },
              {
                path: ":instanceId/interactivities/:interactivityId",
                element: <InteractivityDetails />,
              },
            ],
          },
          {
            path: "prize_disbursement",
            element: <BlankAuthLayout />,
            children: [
              {
                index: true,
                element: <PrizeDisbursementReport />,
              },
              {
                path: ":eliminationRoundId",
                element: <PrizeDisbursementDetails />,
              },
            ],
          },
        ],
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      // {
      //   path: "reports",
      //   element: <Reports />,
      // },
      marketingManagerRoutes,
      { path: "*", element: <PageNotFound /> },
    ],
  },
  {
    element: (
      <ErrorBoundary>
        <BlankAuthLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: "playout_manager_tab",
        element: <PlayoutManagerTab />,
      },
      {
        path: "playout_manager_tab_v2",
        element: <PlayoutManagerTabV2 correlationId={ulid()} />,
      },
      {
        path: "playout_manager_tab_play",
        element: <PlayoutManagerTabPlay />,
      },

      { path: "*", element: <PageNotFound /> },
    ],
  },
]);
