import {
  useEffect,
  useRef,
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
} from "react";
import { REGION } from "../constants";
import { IoTConnectionStatuses, IotDevice } from "./iotdevice";
import { useWebSocket } from "./use_websocket";

export interface IotProviderProps {
  iotWssUrl: string;
  apiUrl: string;
  userId?: string | undefined;
  sessionId?: string | undefined;
  correlationId?: string | undefined;
  hasIotCredentialsCaching?: boolean | undefined;
}

type SubscribeFunctionCb = (payload: unknown) => void;
type SubscribeFunctionErrorCb = (error: Error) => void;
export type IoTContextProps = {
  instance?: IotDevice;
  subscribe?: IotDevice["subscribe"];
  subscribeSync?: IotDevice["subscribeSync"];
  publish?: IotDevice["publish"];
  unsubscribe?: IotDevice["unsubscribe"];
  unsubscribeSync?: IotDevice["unsubcribeSync"];
  status: IoTConnectionStatuses;
  userPing?: IotDevice["userPing"];
};

export const IotContext = createContext<IoTContextProps>({
  status: "closed",
});

export function IotProvider({
  children,
  userId,
  apiUrl,
  iotWssUrl,
  sessionId,
  correlationId,
  hasIotCredentialsCaching,
}: { children: ReactNode } & IotProviderProps) {
  console.log("use_iot_device: IotProvider: Called");
  const effectRef = useRef<string | null | undefined>(null);

  const { setConnectionStatus, setPublish } = useWebSocket();

  const [status, setStatus] = useState<IoTConnectionStatuses>("closed");
  let isConnected = false;

  const [iot] = useState(() => {
    console.log("[tako] use_iot_device.tsx: Instantiating new iotdevice...");
    return new IotDevice(iotWssUrl, apiUrl, REGION, sessionId, correlationId, {
      prefix: "admin",
      hasIotCredentialsCaching,
    });
  });

  function publish(topic: string, payload: unknown) {
    return iot.publish(topic, JSON.stringify(payload));
  }

  function subscribe(
    topic: string,
    cb: SubscribeFunctionCb,
    errCb: SubscribeFunctionErrorCb
  ) {
    return iot.subscribe(topic, cb, errCb);
  }

  function unsubscribe(topic: string, subscriptionId?: string) {
    return iot.unsubscribe(topic, subscriptionId);
  }

  function subscribeSync(
    topic: string,
    cb: SubscribeFunctionCb,
    errCb: SubscribeFunctionErrorCb
  ) {
    return iot.subscribeSync(topic, cb, errCb);
  }

  function unsubscribeSync(topic: string, subscriptionId?: string) {
    return iot.unsubcribeSync(topic, subscriptionId);
  }

  function handleStatusChange(nextStatus: IoTConnectionStatuses) {
    if (status !== nextStatus) {
      setStatus(nextStatus);
      setConnectionStatus(nextStatus);
    }
  }

  iot.onStatusChange(handleStatusChange);

  useEffect(() => {
    if (effectRef.current === userId) return;

    (async () => {
      if (isConnected || userId) {
        await iot.disconnect();
      }

      iot.onStatusChange(handleStatusChange);
      await iot.connect(userId || undefined);
      isConnected = true;
    })();

    effectRef.current = userId;

    return () => {
      if (iot) {
        (async () => {
          if (userId) {
            await iot.disconnect();
            isConnected = false;
          }
        })();
      }
    };
  }, [userId]);

  const values = useMemo(
    () => ({
      instance: iot,
      subscribe,
      publish,
      unsubscribe,
      subscribeSync,
      unsubscribeSync,
      status,
    }),
    [status]
  );

  return <IotContext.Provider value={values}>{children}</IotContext.Provider>;
}

export function useIotSubscribe(
  topic: string,
  enabled: boolean,
  cb: SubscribeFunctionCb,
  deps?: Array<unknown>,
  errCb?: SubscribeFunctionErrorCb
) {
  const ctx = useContext(IotContext);
  const dependencyList = Array.isArray(deps)
    ? [...deps, ctx.status]
    : [ctx.status];

  useEffect(() => {
    if (enabled) {
      if (ctx.subscribeSync && ctx.unsubscribeSync) {
        const subId = ctx.subscribeSync(topic, cb, errCb);

        return () => {
          if (ctx.unsubscribeSync && subId) ctx.unsubscribeSync(topic, subId);
        };
      }
    }
  }, dependencyList);
}

export function useIotDevice() {
  const ctx = useContext(IotContext);

  return ctx;
}
