import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// import Header from "@takoapp/web/src/app/(protected)/header";
import Header from "../app/(protected)/header";
import MissingPage from "@takoapp/web/assets/400.svg";
import { Box, Flex, Image, Text, Button } from "@mantine/core";
import ErrorPage from "@takoapp/web/assets/500.svg";
import LeftSideMask from "@takoapp/web/assets/left-side-mask.png";
import RightSidedMask from "@takoapp/web/assets/right-side-mask.png";
import { TbArrowNarrowLeft } from "react-icons/tb";

export default function ErrorPageComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Header />
      <Box
        sx={() => ({
          background: "#141517",
          height: "50vh",
          position: "relative",
        })}
      >
        <Box
          sx={() => ({
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(255, 255, 255, .1)",
            height: "100%",
            width: "50%",
            maskImage: `url(${LeftSideMask})`,
            maskRepeat: "repeat-y",
          })}
        />
        <Box
          sx={() => ({
            position: "absolute",
            right: 0,
            top: 0,
            background: "rgba(255, 255, 255, .1)",
            height: "100%",
            width: "50%",
            maskImage: `url(${RightSidedMask})`,
            maskRepeat: "repeat-y",
          })}
        />
        <Flex justify="center" align="center">
          <Flex direction="column" justify="center" align="center" gap="lg">
            <Image src={ErrorPage} />
            <Text size="30px" color="#C1C2C5">
              Internal Server Error
            </Text>
            <Text size="20px" color="#565B64" w="378px" align="center">
              The server has been deserted for a while. Please be patient or try
              again later.
            </Text>
            <Button
              color="yellow"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Flex align="center">
                <TbArrowNarrowLeft color="black" size="15" />
                <Text color="black"> Back to Previous Page</Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
