import { Box, Flex, Text } from "@mantine/core";
import { trpc } from "@takoapp/web/utils/trpc";
import { useCallback } from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { Outlet, useNavigate, useParams } from "react-router-dom";

export default function SinglePlaylistLayout() {
  const navigate = useNavigate();
  const params = useParams();
  const { data, isLoading } = trpc.playlist.getById.useQuery(
    params.playlistId ?? "",
    {
      enabled: !!params.playlistId,
    }
  );
  const isFromArchived = data?.isArchived ?? false;

  const onBack = useCallback(() => {
    if (isLoading) return;

    if (isFromArchived) return navigate("../archived", { relative: "path" });

    return navigate("..");
  }, [isLoading, isFromArchived]);

  return (
    <>
      <Box
        sx={() => ({
          background: "#2A2B2E",
          border: "1px solid #373A40",
          cursor: "pointer",
        })}
        py={16}
        px={32}
        onClick={onBack}
      >
        <Flex align="center" gap="xl">
          <RiArrowLeftLine />
          <Text>
            {isLoading
              ? "Loading..."
              : isFromArchived
              ? "Return to Archived Playlist"
              : "Return to playlist"}
          </Text>
        </Flex>
      </Box>
      <Outlet />
    </>
  );
}
