export interface AWSCognitoCredentialOptions {
  IdentityPoolId: string;
  Region: string;
}

export interface AWSCredentials {
  aws_region: string;
  aws_access_id: string;
  aws_secret_key: string;
  aws_sts_token?: string;
  aws_identity_id?: string;
  expiration?: string; // ISO 8901 timestamp
}

export type DeviceCallback = () => void;
export type DeviceMessageCallback = (topic: string, payload: any) => void;
export type DeviceErrorCallback = (error: Error | string) => void;

export type DeviceConnectResponse = {
  clientId: string;
  expiration: string; // ISO 8901 timestamp
};

export type DeviceSubscriptionGrant = {
  topic: string;
  qos: number;
};

export type DeviceSubscribeResponse = {
  grants: DeviceSubscriptionGrant[];
  subscriptionId: string;
};

export type DeviceUnsubscribeResponse = {
  packet: any;
};

export type DeviceSetIdentityResponse = {
  clientId: string;
};

export type DeviceUnsetIdentityResponse = {
  clientId: string;
};

export interface IIotDevice {
  setOnConnect: (listener: DeviceCallback) => void;
  setOnClose: (listener: DeviceCallback) => void;
  setOnReconnect: (listener: DeviceCallback) => void;
  setOnOffline: (listener: DeviceCallback) => void;
  setOnError: (listener: DeviceErrorCallback) => void;
  setOnMessage: (listener: DeviceMessageCallback) => void;

  connect: (
    identityProvider?: string,
    token?: string
  ) => Promise<DeviceConnectResponse>;
  subscribe: (topics: string[]) => Promise<DeviceSubscribeResponse>;
  unsubscribe: (topics: string[]) => Promise<DeviceUnsubscribeResponse>;
  publish: (topic: string, message: string) => Promise<void>;

  setIdentity: (
    idp: string,
    token: string
  ) => Promise<DeviceSetIdentityResponse>;

  unsetIdentity: () => Promise<DeviceUnsetIdentityResponse>;

  disconnect: () => Promise<void>;

  isConnected: () => boolean;
}
/**
 *
 * IotDevice.subscribe('chatStored', (payload: string) => {})
 * IoTDevice.unsubscribe([''])
 *
 */

export type PlaylistType = {
  playlistId: string;
  description?: string;
  groups: GroupType[];
  tags: { label: string; value: string }[];
};

export type SceneType = {
  sceneId?: string;
  playlistId?: string;
  name: string;
  description: string;
  tags: { label: string; value: string }[];
  interactivities: Interactivity[];
  isDeleted?: boolean;
};

// This should be consistent with the "InteractivityType" defined
// in packages/core/src/sqldb.ts
export enum InteractivityType {
  POLL = "POLL",
  QUIZ = "QUIZ",
  TITLING = "TITLING",
  PREDICTION = "PREDICTION",
  ADS = "ADS",
  AGREEMENT_MODAL = "AGREEMENT_MODAL",
  VOUCHER = "VOUCHER",
}

export type Interactivity = {
  interactivityId?: string;
  question?: string;
  metadata: any;
  type: InteractivityType;
  options: InteractivityOptionType[];
  assignedGroupIds: string[];
  order?: number;
  isLocked?: boolean;
};

export type InteractivityOptionType = {
  optionId?: string;
  optionText: string;
  destinationGroupId: string;
  order: number;
  imageUrl?: string | undefined;
};

export type GroupType = {
  groupId: string;
  name: string;
};
export type InteractivityDetails = {
  id: string;
  groupIds: string;
  content: string;
  title: string;
  type: string;
  currentParticipantCount: number;
  participantCount: number;
  participatedCount: number;
  idleCount: number;
  participated: string[];
  order: number;
};

export type GroupDetails = {
  id: string;
  name: string;
  isDefault: boolean;
  participantCount: number;
};

export type InteractivitiesCountResponse = {
  sceneId: string;
  instanceId: string;
  playlistId: string;
  interactivities: Record<string, InteractivityDetails>;
  groups: Record<string, GroupDetails>;
};

export type SubscribeFunctionCb = (payload: unknown) => void;
export type SubscribeFunctionErrorCb = (error: Error) => void;

export const postJson = async <T, V>(
  url: string,
  body: T,
  headers?: Record<string, string>
): Promise<V | null> => {
  const resp = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(headers ?? {}),
    },
    body: JSON.stringify(body),
  });

  const rtype = resp.headers.get("Content-Type");

  if (!rtype) {
    return null;
  }

  if (rtype.startsWith("application/json") && resp.ok) {
    const j: V = (await resp.json()) as V;
    return j;
  }

  const err = await resp.text();
  console.error(`postJson(): ${err}`);
  throw new Error(err);
};

export const getJson = async <V>(
  url: string,
  headers?: Record<string, string>
): Promise<V | null> => {
  const resp = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      ...(headers ?? {}),
    },
  });

  const rtype = resp.headers.get("Content-Type");

  if (!rtype) {
    return null;
  }

  if (rtype.startsWith("application/json") && resp.ok) {
    const j: V = (await resp.json()) as V;
    return j;
  }

  const err = await resp.text();
  console.error(`getJson(): ${err}`);
  throw new Error(err);
};

export const toBase64 = (uint8Buffer: Uint8Array) => {
  const base64 = btoa(
    uint8Buffer.reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, "")
  );

  return base64;
};

export type SubscribeFunctionCbPromise = (data: any) => Promise<void>;

export type EliminationRound = {
  eliminationRoundId: string;
  eliminationRoundMessageTemplateId: string;
  playlistId: string;
  instanceId: string;
  groupId: string;
  name: string;
  description?: string | null;
  source?: string | null;
  winnerNumber: number | null;
  bufferWinnerNumber?: number | null;
  hasConfirmedWinners?: boolean | null;
  prize?: number | null;
  status?: "OPEN" | "CLOSED" | undefined;
  scheduleDate?: string | null;
  dateWinnersDrawn?: string | null;
  isDeleted?: boolean | null;
};
