export const LocalStorage = {
  set: <T>(key: string, val: T) => {
    localStorage.setItem(key, JSON.stringify(val));
  },

  get: <T>(key: string): T | null => {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        const ret = JSON.parse(item) as T;
        return ret;
      } catch (err: any) {
        // Ignore
      }
    }

    return null;
  },

  remove: <T>(key: string) => {
    localStorage.removeItem(key);
  },
};

export const getParticipantId = () => {
  const id = localStorage.getItem("participantId");
  if (id && id.startsWith('"')) {
    return JSON.parse(id);
  }

  return id;
};

export const getChatSessionId = () => {
  const data = localStorage.getItem("chatSessionId");
  if (data) {
    return JSON.parse(data) as string;
  }

  return undefined;
};

export const setChatSessionId = (id: string | undefined) => {
  if (!id) {
    localStorage.setItem("chatSessionId", JSON.stringify(null));
  }

  localStorage.setItem("chatSessionId", JSON.stringify(id));
};

export const getAccessToken = () => {
  return LocalStorage.get<string>("accessToken");
};
