import { Stack, Title } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { useFeatureFlag } from "@takoapp/web/utils/feature_flag";
import PageNotFound from "@takoapp/web/components/400";

export default function ThemeLayout() {
  const { hasThemeManagerEnabled } = useFeatureFlag();

  if (!hasThemeManagerEnabled) {
    return <PageNotFound hasHeader={false} />;
  }

  return (
    <Stack spacing="xl" px="3rem" py="3rem">
      <Title>Theme Manager</Title>
      <Outlet />
    </Stack>
  );
}
