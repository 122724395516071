import { Box, Flex, Title } from "@mantine/core";
import { ContentBar } from "@takoapp/web/components/content_bar";
import { Outlet } from "react-router-dom";

function Drawer() {
  return (
    <ContentBar noPadding width="480px" withStackLayout={false}>
      <Outlet />
    </ContentBar>
  );
}

export default function InteractivityLayout() {
  return (
    <Flex>
      <Drawer />
      <Box
        sx={() => ({
          width: "100%",
          margin: 40,
          background: "#212227",
          border: "1px solid #373A40",
          borderRadius: 8,
          padding: 24,
        })}
      >
        <Title weight="normal" size="18">
          Interactivity Preview
        </Title>
      </Box>
    </Flex>
  );
}
