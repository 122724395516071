import { createContext, useContext, useState } from "react";
import { Box, Loader, Overlay } from "@mantine/core";
import { RiCheckboxCircleFill } from "react-icons/ri";

type ActionOverlayContextProps = {
  isVisible: boolean;
  isLoading: boolean;
  prompt: string;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  clear: (hasTimeout: boolean, timeout: number) => void;
  load: (message: string) => void;
};

const noop = () => {};
export const ActionOverlayContext = createContext<ActionOverlayContextProps>({
  isVisible: false,
  isLoading: false,
  prompt: "",
  setIsVisible: noop,
  setIsLoading: noop,
  setPrompt: noop,
  clear: noop,
  load: noop,
});

export function useActionOverlay() {
  const context = useContext(ActionOverlayContext);

  return context;
}

function ActionOverlay({
  isLoading,
  prompt,
}: {
  isLoading: boolean;
  prompt: string;
}) {
  return (
    <>
      <Overlay opacity={0.6} color="#000" />
      {isLoading ? (
        <Loader
          color={import.meta.env.VITE_PRIMARY_COLOR ?? "#EAAC21"}
          size={60}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: 1000,
          }}
        />
      ) : (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: 1000,
            color: import.meta.env.VITE_PRIMARY_COLOR ?? "#EAAC21",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
          }}
        >
          <RiCheckboxCircleFill size={58} />
          {prompt}
        </Box>
      )}
    </>
  );
}

function ActionOverlayProvider({ children }: { children: React.ReactNode }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState("");

  const clear = (hasTimeout: boolean = false, timeout: number = 500) => {
    setIsLoading(false);

    if (hasTimeout) {
      setTimeout(() => {
        setIsVisible(false);
        setPrompt("");
      }, timeout);
      return;
    }

    setIsVisible(false);
    setPrompt("");
  };

  const load = (message: string) => {
    setPrompt(message);
    setIsVisible(true);
    setIsLoading(true);
  };

  return (
    <ActionOverlayContext.Provider
      value={{
        isVisible,
        isLoading,
        prompt,
        setIsVisible,
        setIsLoading,
        setPrompt,
        clear,
        load,
      }}
    >
      {children}
      {isVisible && <ActionOverlay isLoading={isLoading} prompt={prompt} />}
    </ActionOverlayContext.Provider>
  );
}

export default ActionOverlayProvider;
