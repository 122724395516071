import { Box, Flex } from "@mantine/core";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";

export default function MarketingManagerLayout() {
  return (
    <Flex>
      <Sidebar />
      <Box w="100%" p={32}>
        <Outlet />
      </Box>
    </Flex>
  );
}
