import { Box, Center, Text, Popover, Avatar, Flex, Tooltip } from "@mantine/core";
import KromaLogo from "@takoapp/web/assets/kroma-logo.png";
import AvatarLogo from "@takoapp/web/assets/avatar.png";
import { AiFillCaretDown } from "react-icons/ai";
import { RiLogoutBoxRLine, RiSettings2Fill, RiUser2Fill } from "react-icons/ri";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { trpc } from "@takoapp/web/utils/trpc";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { participantIdAtom } from "../../atom";
import { LocalStorage, getAccessToken } from "../../utils/localstorage";

type HeaderProps = {};

function Header({ }: HeaderProps) {
  const { mutate, isLoading } = trpc.auth.logout.useMutation();
  const [participantId, setParticipantId] = useAtom(participantIdAtom);
  const navigate = useNavigate();
  const handleLogout = async () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      mutate(
        { accessToken },
        {
          onSettled() {
            LocalStorage.set("accessToken", "");
            LocalStorage.set("idToken", "");
            LocalStorage.set("refreshToken", "");
            LocalStorage.set("expiresIn", 0);
            LocalStorage.set("chatSessionId", "");
            setParticipantId("");
            navigate("/login");
          },
        }
      );
    } else {
      console.error("No access token found in local storage");
    }
  };

  return (
    <Box
      component="header"
      sx={() => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 70,
        background: "#25262B",
        position: "relative",
        borderBottom: "1px solid #373A40",
        width: "100%",
      })}
    >
      <Center
        sx={() => ({
          borderRight: "1px solid #373A40",
          borderBottom: "1px solid #373A40",
          width: 90,
          height: "100%",
        })}
      >
        <img src={KromaLogo} height={45} />
      </Center>
      <Flex justify="space-between" gap={20} align="center">
        {/* <BsFillPersonPlusFill size={19} />
        <RiSettings2Fill size={19} /> */}
        <Box
          sx={{
            marginRight: 20,
            cursor: "pointer",
          }}
        >
          <Popover
            position="bottom"
            withArrow
            shadow="md"
            arrowPosition="side"
            withRoles
          >
            <Popover.Target>
              <Flex align="center" sx={{ marginRight: 10, marginLeft: 10 }}>
                <Box
                  sx={{
                    margin: 10,
                    backgroundColor: "#FEB43A",
                    borderRadius: "50%",
                  }}
                >
                  <img src={AvatarLogo} />
                </Box>
                <AiFillCaretDown size={19} style={{ color: "#565B64" }} />
              </Flex>
            </Popover.Target>
            <Popover.Dropdown>
              <Box
                onClick={() => {
                  navigate("/profile", { replace: true });
                }}
              >
                <Flex align="center" gap={5} mt={5}>
                  <RiUser2Fill size={19} />
                  <Text size="sm" pr={15}>
                    My Profile
                  </Text>
                </Flex>
              </Box>
              <Box onClick={handleLogout}>
                <Flex align="center" gap={5}>
                  <RiLogoutBoxRLine size={19} />
                  <Text size="sm" pr={15}>
                    Logout
                  </Text>
                </Flex>
              </Box>
            </Popover.Dropdown>
          </Popover>
        </Box>
      </Flex>
    </Box>
  );
}

export default Header;
