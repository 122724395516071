import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { Mutex } from "async-mutex";

import {
  IWebSocket,
  PendingCloseEvent,
  WebSocketCommandResult,
  WebSocketBinarySubscriptionData,
  WebSocketTextSubscriptionData,
  createWebSocket,
} from "@adsparkph/bento_ws";
import { ulid } from "ulid";
import { SubscribeFunctionCbPromise, postJson, toBase64 } from "./common";
import { useWebSocket } from "./use_websocket";
import { trpc } from "./trpc";
import { createLocker, createSharedRecordsAccessor } from "./concurrency";

export type BentoSubscribeResult = {
  success: string;
  topic: string;
  result?: WebSocketCommandResult;
  message?: string;
};

interface BentoWSContextProps {
  isBentowsEnabled: boolean;
  isConnected: boolean;
  bentoPublish: (
    topic: string,
    message: unknown,
    correlationId?: string
  ) => Promise<void>;
  bentoSubscribe: (
    topic: string,
    cb: SubscribeFunctionCbPromise,
    correlationId?: string
  ) => Promise<BentoSubscribeResult>;
  unsubscribe: (topic: string, key: string, correlationId?: string) => void;
  wso: IWebSocket | null;
}

interface BentoWSProviderProps {
  children: ReactNode;
  apiUrl: string;
  iotWssUrl: string;
  userId?: string;
  sessionId: string;
  correlationId: string;
  hasIotCredentialsCaching?: boolean;
}

const cid = (correlationId?: string) =>
  correlationId ? `[${correlationId}] ` : "";

const defaultContext: BentoWSContextProps = {
  isBentowsEnabled: false,
  isConnected: false,
  bentoPublish: async () => {
    // console.warn("Publish function not implemented.");
  },
  bentoSubscribe: async (topic: string, cb: SubscribeFunctionCbPromise) => {
    // console.warn("Subscribe function not implemented.");
    return {
      success: "error",
      topic,
      message: "Subscribe function not implemented.",
    };
  },
  unsubscribe: () => {},
  wso: null,
};

const BentoWSContext = createContext<BentoWSContextProps>(defaultContext);
interface SubscriptionCallback {
  (data: any): void;
}

export const BentoWSProvider: React.FC<BentoWSProviderProps> = ({
  children,
  apiUrl,
  iotWssUrl,
  userId,
  sessionId,
  correlationId,
  hasIotCredentialsCaching,
}) => {
  console.log("[bentows] use_bentows.tsx: BentoWSProvider: Called");
  const apiEndpoint = `${apiUrl}`;
  const [wso, setWso] = useState<IWebSocket | null>(null);
  const [sessionIds, setSessionId] = useState(`chatform:${ulid()}`);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const { connectionStatus, setConnectionStatus } = useWebSocket();

  const mountedRef = useRef(false);
  const mutexRef = useRef(new Mutex());

  const connectionId = ulid();

  const locker = createLocker({ isLocal: true, mutexObject: mutexRef.current });
  const CONN_LOCKER = "connLocker";

  const sockOnOpen = async (e: Event) => {
    console.log("[bentows] *** Connected to the chat ***", e);
    localStorage.setItem("takoClientId", connectionId);
    setIsConnected(true);
    setConnectionStatus("connected");
  };

  async function sockOnClose(e: CloseEvent) {
    console.log("[bentows] *** Disconnected from the chat ***");
    setIsConnected(false);
    setConnectionStatus("closed");
  }

  async function sockOnError(error: Event) {
    console.log(`[bentows] *** Error: ${JSON.stringify(error)} ***`);
  }

  async function sockOnPendingClose(e: PendingCloseEvent) {
    console.log(
      `[bentows] *** WARNING: Disconnecting in ${e.minutesLeft} mins ***`
    );
  }
  const connect = async () => {
    console.log("[bentows] use_bentows.tsx: connect(): Connecting to: ", {
      apiUrl,
      sessionIds,
      iotWssUrl,
    });

    const queryParam = `sessionId=${encodeURIComponent(
      sessionIds
    )}&connectionId=${encodeURIComponent(connectionId)}`;

    await locker.lockThenRun(CONN_LOCKER, async () => {
      const resp = (await postJson(`${apiUrl}/v2/wstokens/new?${queryParam}`, {
        correlationId,
        participantId: userId,
      })) as { token: string };

      const wsUrl = `${iotWssUrl}?t=${encodeURIComponent(resp.token)}`;
      const socket = createWebSocket(wsUrl, {
        onOpen: sockOnOpen,
        onClose: sockOnClose,
        onError: sockOnError,
        onPendingClose: sockOnPendingClose,
      });

      console.log("[bentows] connecting . .");

      if (socket) {
        setWso(socket);
      }
    });
  };

  const disconnect = async () => {
    console.log(
      "[bentows] use_bentows.tsx: disconnect(): Disconnecting from: ",
      { iotWssUrl }
    );

    await locker.lockThenRun(CONN_LOCKER, async () => {
      console.log("[bentows] disconnecting . .");

      if (wso) {
        await wso.sendQuit();
        setWso(null);
      }
    });
  };

  useEffect(() => {
    mountedRef.current = true;

    const func = async () => {
      if (mountedRef.current && userId) {
        await connect();
      }
    };

    func();

    return () => {
      mountedRef.current = false;

      const func = async () => {
        await disconnect();
      };

      func();
    };
  }, [userId]);

  const publish = async (
    topic: string,
    payload: unknown,
    correlationId?: string
  ) => {
    console.log("[bentows] use_bentows.tsx: publish(): Called with: ", {
      correlationId,
      topic,
    });

    if (!wso) {
      console.warn(
        `[bentows] use_bentows.tsx: publish(): ${cid(
          correlationId
        )}WebSocket is not connected or it is not enabled.`
      );
      return;
    }

    const result = await wso.sendPublishText(
      topic,
      "application/json",
      JSON.stringify(payload),
      correlationId
    );

    console.log(
      `[bentows] use_bentows.tsx: publish(): ${cid(
        correlationId
      )}Publish result: `,
      result
    );

    if (result.statusCode !== 0) {
      throw new Error(
        `[${correlationId}] use_bentows.txt: publish(): ${result.statusText}`
      );
    }
  };

  const ref = useRef<{
    [topic: string]: { [key: string]: SubscribeFunctionCbPromise };
  }>({});

  const subscribe = async (
    topic: string,
    cb: SubscribeFunctionCbPromise,
    correlationId?: string
  ): Promise<BentoSubscribeResult> => {
    console.log("[bentows] use_bentows.tsx: subscribe(): ", {
      correlationId,
      topic,
    });

    if (!wso) {
      console.warn(
        `bentows] use_bentows.tsx: subscribe(): ${cid(
          correlationId
        )}WebSocket is not connected`
      );

      return {
        success: "error",
        topic,
        message: `${cid(correlationId)}WebSocket is not connected`,
      };
    }

    try {
      const result = await wso.sendSubscribe(
        topic,
        async (data) => {
          console.log(
            `[bentows] use_bentows.tsx: subscribe(): ${cid(
              correlationId
            )}sendSubscribe callback called: `,
            { topic }
          );

          try {
            if (data.mode !== "text") {
              throw new Error(
                `Textual data expected from topic '${data.topicSubscribed}' non-textual data received instead`
              );
            }

            const textArg = data as WebSocketTextSubscriptionData;

            if (!textArg.contentType.startsWith("application/json")) {
              throw new Error(
                `JSON data expected from topic '${data.topicSubscribed}', "${textArg.contentType}" data received instead`
              );
            }

            return cb(JSON.parse(textArg.message));
          } catch (error) {
            console.error(
              `${cid(correlationId)}Error handling subscription data:`,
              error
            );
          }
        },
        correlationId
      );

      console.log(
        `[bentows] use_bentows.tsx: subscribe(): ${cid(
          correlationId
        )}sendSubscribe() done.`
      );
      return { success: "ok", topic, message: "OK", result };
    } catch (err: any) {
      console.warn(
        `[bentows] use_bentows.tsx: subscribe(): ${cid(
          correlationId
        )}Error, ignoring: `,
        err
      );

      return { success: "error", topic, message: err.message };
    }
  };

  const unsubscribe = useCallback(
    (topic: string, key: string) => {
      if (ref.current[topic] && ref.current[topic][key]) {
        delete ref.current[topic][key];

        if (Object.keys(ref.current[topic]).length === 0) {
          wso
            ?.sendUnsubscribe(topic)
            .catch((err) => console.error("Error unsubscribing:", err));
        }
      }
    },
    [wso]
  );

  const value = {
    isBentowsEnabled: true,
    isConnected,
    bentoPublish: publish,
    bentoSubscribe: subscribe,
    unsubscribe,
    wso,
    apiEndpoint,
  };

  return (
    <BentoWSContext.Provider value={value}>{children}</BentoWSContext.Provider>
  );
};

export type BentoSubscribeFunctionCb = (payload: unknown) => Promise<void>;
export type BentoSubscribeFunctionErrorCb = (error: Error) => Promise<void>;

export function useBentoWsSubscribe(
  topic: string,
  enabled: boolean,
  cb: BentoSubscribeFunctionCb,
  deps?: Array<unknown>,
  errCb?: BentoSubscribeFunctionErrorCb
) {
  const { bentoSubscribe } = useContext(BentoWSContext);

  const subscriptionsRef = useRef<Record<string, boolean>>({});
  const mutexRef = useRef<Mutex>(new Mutex());

  const sharedRecords = createSharedRecordsAccessor(
    subscriptionsRef.current,
    mutexRef.current
  );

  if (enabled) {
    const func = async () => {
      await sharedRecords.use(async (records) => {
        const isSubscribed = records[topic];

        if (!isSubscribed) {
          const correlationId = ulid();
          console.log(
            `[bentows] use_bentows.tsx: useBentoWsSubscribe(): [${correlationId}] Starting subscription to '${topic}' topic via Bento WS`
          );

          console.log(
            `[bentows] events.tsx: [${correlationId}] About to call bentoSubscribe() on topic '${topic}'...`
          );

          const subsResult = await bentoSubscribe(
            topic,
            async (payload) => {
              console.log(
                `[bentows] events.tsx: bentoSubscribe(): callback: [${correlationId}] subscribe payload: `,
                { payload }
              );

              try {
                await cb(payload);
              } catch (err: any) {
                if (errCb) {
                  await errCb(err);
                }
              }
            },
            correlationId
          );

          if (subsResult.success === "ok") {
            console.log(
              `[bentows] events.tsx:  bentoSubscribe(): [${correlationId}] Subscription to '${topic}' OK.`
            );

            records[topic] = true;
          } else {
            if (errCb) {
              await errCb(
                new Error(
                  `[${correlationId}] Unable to subscribe to '${topic}' topic: ${subsResult.message}`
                )
              );
            }
          }
        } else {
          console.log(
            `[bentows] use_bentows.tsx: useBentoWsSubscribe(): Already subscribed to '${topic}' topic`
          );
        }
      });
    };

    func();
  }
}

// Hook to use the WebSocket context
export const useBentoWS = () => useContext(BentoWSContext);
