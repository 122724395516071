import { RouteObject } from "react-router-dom";
import MarketingManagerLayout from "./layout";
import { lazy } from "react";
import { isEnabled } from "@takoapp/web/utils/feature_flag";

const AdsPage = lazy(() => import("./ads/page"));
const CreateAdsPage = lazy(() => import("./ads/create/page"));
const UpdateAdsPage = lazy(() => import("./ads/[id]/update/page"));
const CustomizeAdsPage = lazy(() => import("./ads/[id]/customize/page"));

const UserAgreementPage = lazy(() => import("./user_agreement/page"));
const CreateUserAgreementPage = lazy(
  () => import("./user_agreement/create/page")
);
const UpdateUserAgreementPage = lazy(
  () => import("./user_agreement/[id]/update/page")
);

const VoucherPage = lazy(() => import("./vouchers/page"));
const VoucherCreatePage = lazy(() => import("./vouchers/create/page"));
const UpdateVoucherDetails = lazy(() => import("./vouchers/[id]/update/page"));
const CustomizeVoucher = lazy(() => import("./vouchers/[id]/customize/page"));

const RaffleEntriesLayout = lazy(() => import("./raffle_entries/layout"));
const RaffleEntriesPage = lazy(() => import("./raffle_entries/page"));
const CreateEliminationRoundPage = lazy(
  () => import("./raffle_entries/create/page")
);
const EliminationRoundPage = lazy(() => import("./raffle_entries/[id]/page"));
const UpdateEliminationRoundPage = lazy(
  () => import("./raffle_entries/[id]/update/page")
);

export const marketingManagerRoutes: RouteObject = {
  path: "/media_manager",
  element: <MarketingManagerLayout />,
  children: [
    {
      index: true,
      element: <AdsPage />,
    },
    {
      path: "ads",
      element: <AdsPage />,
      children: [
        {
          path: "create",
          element: <CreateAdsPage />,
        },
        {
          path: ":adsId",
          children: [
            {
              path: "update",
              element: <UpdateAdsPage />,
            },
            {
              path: "customize",
              element: <CustomizeAdsPage />,
            },
          ],
        },
      ],
    },
    {
      path: "vouchers",
      element: <VoucherPage />,
      children: [
        {
          path: "create",
          element: <VoucherCreatePage />,
        },
        {
          path: ":voucherId",
          children: [
            {
              path: "update",
              element: <UpdateVoucherDetails />,
            },
            {
              path: "customize",
              element: <CustomizeVoucher />,
            },
          ],
        },
      ],
    },
    {
      path: "user-agreement",
      element: <UserAgreementPage />,
      children: [
        {
          path: "create",
          element: <CreateUserAgreementPage />,
        },
        {
          path: ":agreementModalId",
          children: [
            {
              path: "update",
              element: <UpdateUserAgreementPage />,
            },
          ],
        },
      ],
    },
    isEnabled(import.meta.env.VITE_HAS_RAFFLE_ENTRIES_ENABLED)
      ? {
          path: "raffle_entries",
          element: <RaffleEntriesLayout />,
          children: [
            {
              index: true,
              element: <RaffleEntriesPage />,
            },
            {
              path: "create",
              element: <CreateEliminationRoundPage />,
            },
            {
              path: ":eliminationRoundId",
              children: [
                {
                  index: true,
                  element: <EliminationRoundPage />,
                },
                {
                  path: "update",
                  element: <UpdateEliminationRoundPage />,
                },
              ],
            },
          ],
        }
      : {},
  ],
};
