import { useAtom } from "jotai";
import { ulid } from "ulid";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { Suspense, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import { theme } from "./theme";
import { trpc } from "./utils/trpc";

import ActionOverlayProvider from "./components/action_overlay";
import { FeatureFlagProvider } from "./utils/feature_flag";
import { API_BASE_URL, IOT_WSS_URL, HAS_BENTO_WS_ENABLED } from "./constants";
import { participantIdAtom } from "./atom";
import { setChatSessionId, getAccessToken } from "./utils/localstorage";
import {
  CHAT_CLIENT_ID,
  COMPONENT_API_BASE_URL,
  WS_ENDPOINT_URL,
} from "src/constants";
import { EventProvider } from "./utils/events";
import { WebSocketProvider } from "./utils/use_websocket";

type SignalType = AbortSignal | null | undefined;

const sessId = `${CHAT_CLIENT_ID}:${ulid()}`;
setChatSessionId(sessId);
console.info("App.tsx: Chat session ID: ", sessId);

export default function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${import.meta.env.VITE_TRPC_URL}/trpc`,
          maxURLLength: 2083,
          // TODO: remove fetch options once we have auth
          fetch(url, options) {
            const modifiedSignal = options?.signal as SignalType;
            return fetch(url, {
              ...options,
              signal: modifiedSignal,
              mode: "cors",
            });
          },

          headers() {
            const accessToken = getAccessToken();
            if (accessToken) {
              return {
                Authorization: `Bearer ${accessToken}`,
              };
            }

            return {};
          },
        }),
      ],
    })
  );
  const [participantId, setParticipantId] = useAtom(participantIdAtom);

  const apiEndpoint = `${API_BASE_URL}/api/v1`;

  return (
    <FeatureFlagProvider>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <WebSocketProvider
            userId={participantId}
            sessionId={sessId}
            correlationId={ulid()}
            iotApiUrl={apiEndpoint}
            iotWssUrl={IOT_WSS_URL}
            bentoWsApiUrl={COMPONENT_API_BASE_URL}
            bentoWsWssUrl={WS_ENDPOINT_URL}
            implementation={HAS_BENTO_WS_ENABLED ? "bento" : "iot"}
          >
            <MantineProvider withNormalizeCSS theme={theme}>
              <Notifications />
              <ModalsProvider>
                <ActionOverlayProvider>
                  <EventProvider>
                    <Suspense>
                      <RouterProvider router={routes} />
                    </Suspense>
                  </EventProvider>
                </ActionOverlayProvider>
              </ModalsProvider>
            </MantineProvider>
          </WebSocketProvider>
        </QueryClientProvider>
      </trpc.Provider>
    </FeatureFlagProvider>
  );
}
