import { useWebSocket } from "./use_websocket";
import { useIotSubscribe } from "./use_iot_device";
import { useBentoWsSubscribe } from "./use_bentows";

type SubscribeFunctionCb = (payload: unknown) => void;
type SubscribeFunctionErrorCb = (error: Error) => void;

export function useWsSubscribe(
  topic: string,
  cb: SubscribeFunctionCb,
  deps?: Array<unknown>,
  errCb?: SubscribeFunctionErrorCb
) {
  const { implementation, connectionStatus } = useWebSocket();

  console.log("use_pubsub.tsx: useWsSubscribe(): ", { topic, implementation });

  useIotSubscribe(
    topic,
    implementation === "iot",
    (payload: any) => {
      if (implementation === "iot") {
        cb(payload);
      }
    },
    deps,
    errCb
  );

  useBentoWsSubscribe(
    topic,
    implementation === "bento",
    async (payload: any) => {
      if (implementation === "bento") {
        if (connectionStatus === "connected") {
          cb(payload);
        }
      }
    },
    deps,
    async () => {
      if (errCb) errCb;
    }
  );
}
