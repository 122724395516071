export const IOT_WSS_URL = `${import.meta.env.VITE_IOT_WSS_URL}`;
export const COGNITO_IDP = import.meta.env.VITE_IDP_ID ?? "";
export const USER_POOL_ID = import.meta.env.VITE_USER_POOL_ID ?? "";
export const API_BASE_URL = `${import.meta.env.VITE_TRPC_URL}`;
export const COMPONENT_API_BASE_URL = `${import.meta.env.VITE_API_URL}`;
export const WS_ENDPOINT_URL = `${import.meta.env.VITE_WS_ENDPOINT_URL}`;
export const WS_HTTP_ENDPOINT_URL = `${
  import.meta.env.VITE_WS_HTTP_ENDPOINT_URL
}`;

export const HAS_IOT_CREDENTIALS_CACHING =
  (import.meta.env.VITE_HAS_IOT_CREDENTIALS_CACHING ?? "") === "true";

export const HAS_BENTO_WS_ENABLED =
  (import.meta.env.VITE_HAS_BENTO_WS_ENABLED ?? "") === "true";

export const REGION = "ap-southeast-1";

export const STALE_TIME = 900000;
export const CACHE_TIME = 1200000;

export const CHAT_CLIENT_ID = `tako-admin-chat:${import.meta.env.MODE}`;

export const IDLE_GROUP_OPTION = "Idle Group";

export enum EnumInstanceLogActions {
  SELECT = "SELECT",
  DISCONNECT = "DISCONNECT",
  RECONNECT = "RECONNECT",
  JOIN = "JOIN",
  ASSIGN = "ASSIGN",
  IDLE = "IDLE",
  MESSAGE = "MESSAGE",
  START_SCENE = "START_SCENE",
  END_SCENE = "END_SCENE",
  DEFINE_ANSWERS = "DEFINE_ANSWERS",
  START_PLAYLIST = "START_PLAYLIST",
  END_PLAYLIST = "END_PLAYLIST",
  BROADCAST_SCENE = "BROADCAST_SCENE",
  UNBROADCAST_SCENE = "UNBROADCAST_SCENE",
  LOCK_SCENE = "LOCK_SCENE",
  LOCK_INTERACTIVITY = "LOCK_INTERACTIVITY",
  UNKNOWN = "UNKNOWN",
}
