import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  colorScheme: "dark",
  headings: {},
  fontFamily: "Nunito, sans-serif",
  components: {
    Title: {
      styles: (theme) => ({
        root: {
          color: "#fff",
        },
      }),
    },

    Modal: {
      styles: () => ({
        header: {
          zIndex: 300,
        },
      }),
    },
  },
  primaryShade: 5,
  colors: {
    yellow: [
      import.meta.env.VITE_PRIMARY_COLOR || "#fff8da",
      import.meta.env.VITE_PRIMARY_COLOR || "#ffe9ad",
      import.meta.env.VITE_PRIMARY_COLOR || "#ffdb7d",
      import.meta.env.VITE_PRIMARY_COLOR || "#ffcc4b",
      import.meta.env.VITE_PRIMARY_COLOR || "#ffbd1a",
      import.meta.env.VITE_PRIMARY_COLOR || "#e6a400",
      import.meta.env.VITE_LIGHT_PRIMARY_COLOR || "#b37f00",
      "#805b00",
      "#4e3700",
      "#1e1100",
    ],
    dark: [
      "#C1C2C5",
      "#A6A7AB",
      "#909296",
      "#5C5F66",
      "#373A40",
      "#2C2E33",
      "#25262B",
      "#1A1B1E",
      "#141517",
      "#2C2E33",
    ],
    orange: [
      "#FFF4E6",
      "#FFE8CC",
      "#FFD8A8",
      "#FFC078",
      "#FFA94D",
      "#FF922B",
      "#FD7E14",
      "#F76707",
      "#E8590C",
      "#D9480F",
    ],
  },
};
