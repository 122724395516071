import { useState } from "react";
import { Flex, Stack, Center, Box, Text } from "@mantine/core";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

export interface IContentBarProps {
  children: JSX.Element | JSX.Element[];
  width: string;
  withStackLayout?: boolean;
  noPadding?: boolean;
  absoluteButton?: boolean;
}

export function ContentBar({
  children,
  width,
  withStackLayout = true,
  noPadding = false,
  absoluteButton = false,
}: IContentBarProps) {
  const [hideBar, setHideBar] = useState<boolean>(false);

  return (
    <Flex
      sx={{
        width: hideBar ? "1" : width,
        minWidth: hideBar ? "1" : width,
        position: "relative",
      }}
    >
      {withStackLayout ? (
        <Stack
          sx={(theme) => ({
            paddingTop: noPadding ? 0 : theme.spacing.xl,
            background: "#212227",
            border: "1px solid #373A40",
            width: hideBar ? "0" : "100%",
            marginLeft: "0px !important",
            marginRight: "0px !important",
            maxHeight: "92vh",
            overflow: "hidden",
            transition: "width 0.5s ease",
            position: "relative",
          })}
        >
          {children}
        </Stack>
      ) : (
        <Box
          sx={(theme) => ({
            paddingTop: noPadding ? 0 : theme.spacing.xl,
            background: "#212227",
            border: "1px solid #373A40",
            width: hideBar ? "0" : "100%",
            marginLeft: "0px !important",
            marginRight: "0px !important",
            minHeight: "92vh",
            overflow: "hidden",
            transition: "width 0.5s ease",
            position: "relative",
          })}
        >
          {hideBar ? <Box w={240}></Box> : children}
        </Box>
      )}

      <Center
        sx={{
          minHeight: "92vh",
          position: absoluteButton ? "absolute" : "relative",
          left: absoluteButton ? "100%" : "unset",
        }}
      >
        <Box
          mx="xl"
          sx={() => ({
            background: "#212227",
            border: "1px solid #373A40",
            borderRadius: "0px 10px 10px 0px",
            margin: "0px 0px 0px -1px !important",
            width: "23px",
            height: "114px",
            cursor: "pointer",

            "&:hover": {
              backgroundColor: "#363840",
            },
          })}
          onClick={() => setHideBar((o) => !o)}
        >
          <Center style={{ width: "23px", height: "114px" }}>
            {hideBar ? <RiArrowRightSLine /> : <RiArrowLeftSLine />}
          </Center>
        </Box>
      </Center>
    </Flex>
  );
}
