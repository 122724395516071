import { Box, Group, Stack, Text } from "@mantine/core";
import { ContentBar } from "@takoapp/web/components/content_bar";
import { TbAd, TbArrowsShuffle2, TbTicket } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";
import style from "./sidebar.module.css";

import { useFeatureFlag } from "@takoapp/web/utils/feature_flag";
export default function Sidebar() {
  const location = useLocation();
  const { hasUserAgreementModal, hasRaffleEntriesEnabled } = useFeatureFlag();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <ContentBar noPadding width="480px" withStackLayout={false}>
      <Box px="24px" py="32px">
        <Text weight="700" fz="24px" color="yellow">
          Media Manager
        </Text>
      </Box>

      <Stack>
        <Link to="ads" className={style.link}>
          <Group
            className={cx(
              style.sidebar,
              (isActive("/media_manager/ads") || isActive("/media_manager")) &&
                style.active
            )}
            align="start"
          >
            <TbAd size="25" color="#565B64" />
            <Stack spacing="8px">
              <Text className={style.name}>Advertisements</Text>
              <Text className={style.description}>
                Create and customize visually appealing Ads
              </Text>
            </Stack>
          </Group>
        </Link>
        <Link className={style.link} to="vouchers">
          <Group
            className={cx(
              style.sidebar,
              isActive("/media_manager/vouchers") && style.active
            )}
            align="start"
          >
            <TbTicket size="25" color="#565B64" />
            <Stack spacing="8px">
              <Text className={style.name}>Vouchers/Coupons</Text>
              <Text className={style.description}>
                Create vouchers for discounts, promotions, or rewards
              </Text>
            </Stack>
          </Group>
        </Link>
        {hasUserAgreementModal && (
          <Link className={style.link} to="user-agreement">
            <Group
              className={cx(
                style.sidebar,
                isActive("/media_manager/user-agreement") && style.active
              )}
              align="start"
            >
              <TbTicket size="25" color="#565B64" />
              <Stack spacing="8px">
                <Text className={style.name}>User Agreement Modals</Text>
                <Text className={style.description}>
                  Create consent modals for raffle draws
                </Text>
              </Stack>
            </Group>
          </Link>
        )}
        {hasRaffleEntriesEnabled && (
          <Link className={style.link} to="raffle_entries">
            <Group
              className={cx(
                style.sidebar,
                isActive("/media_manager/raffle_entries") && style.active
              )}
              align="start"
            >
              <TbArrowsShuffle2 size="25" color="#565B64" />
              <Stack spacing="8px">
                <Text className={style.name}>Manage Raffle Entries</Text>
                <Text className={style.description}>
                  Create elimination rounds to draw winners
                </Text>
              </Stack>
            </Group>
          </Link>
        )}
      </Stack>
    </ContentBar>
  );
}
