import React, { createContext, useContext, useState } from "react";
import { IotProvider } from "./use_iot_device";
import {
  HAS_BENTO_WS_ENABLED,
  HAS_IOT_CREDENTIALS_CACHING,
} from "../constants";
import { BentoWSProvider } from "./use_bentows";

export type WebSocketConnectionStatuses =
  | "connected"
  | "reconnecting"
  | "closed"
  | "offline"
  | "pending";

export type WebSocketImplementation = "unknown" | "iot" | "bento";

export type WebSocketProviderProps = {
  children: React.ReactNode;
  userId?: string;
  iotApiUrl: string;
  iotWssUrl: string;
  bentoWsApiUrl: string;
  bentoWsWssUrl: string;
  sessionId: string;
  correlationId: string;
  implementation: WebSocketImplementation;
};

export type WebSocketProviderContextSetter<T> = React.Dispatch<
  React.SetStateAction<T>
>;

export type WebSocketSubscribeFunc = (
  topic: string,
  cb: (payload: unknown) => void,
  errCb?: (error: Error) => void,
  correlationId?: string
) => Promise<any>;

export type WebSocketSubscribeSyncFunc = (
  topic: string,
  cb: (payload: unknown) => void,
  errCb?: (error: Error) => void,
  correlationId?: string
) => string | undefined;

export type WebSocketPublishFunc = (
  topic: string,
  message: unknown,
  correlationId?: string
) => Promise<void>;

export type WebSocketUnsubscribeFunc = (
  topic: string,
  subscriptionId?: string,
  correlationId?: string
) => Promise<any>;

export type WebSocketUnsubscribeSyncFunc = (
  topic: string,
  subscriptionId?: string,
  correlationId?: string
) => void;

export type WebSocketProviderContextProps = {
  connectionStatus: WebSocketConnectionStatuses;
  setConnectionStatus: WebSocketProviderContextSetter<WebSocketConnectionStatuses>;
  implementation: WebSocketImplementation;
  subscribe: WebSocketSubscribeFunc;
  setSubscribe: WebSocketProviderContextSetter<WebSocketSubscribeFunc>;
  subscribeSync: WebSocketSubscribeSyncFunc;
  setSubscribeSync: WebSocketProviderContextSetter<WebSocketSubscribeSyncFunc>;
  publish: WebSocketPublishFunc;
  setPublish: WebSocketProviderContextSetter<WebSocketPublishFunc>;
  unsubscribe: WebSocketUnsubscribeFunc;
  setUnsubscribe: WebSocketProviderContextSetter<WebSocketUnsubscribeFunc>;
  unsubscribeSync: WebSocketUnsubscribeSyncFunc;
  setUnsubscribeSync: WebSocketProviderContextSetter<WebSocketUnsubscribeSyncFunc>;
};

const defaultContext: WebSocketProviderContextProps = {
  connectionStatus: "closed",
  setConnectionStatus: () => {},
  implementation: "unknown",
  subscribe: () => Promise.resolve({}),
  setSubscribe: () => {},
  subscribeSync: () => "",
  setSubscribeSync: () => {},
  publish: () => Promise.resolve(),
  setPublish: () => {},
  unsubscribe: () => Promise.resolve({}),
  setUnsubscribe: () => {},
  unsubscribeSync: () => {},
  setUnsubscribeSync: () => {},
};

const WebSocketProviderContext =
  createContext<WebSocketProviderContextProps>(defaultContext);

export const WebSocketProvider = ({
  children,
  userId,
  iotApiUrl,
  iotWssUrl,
  implementation,
  bentoWsApiUrl,
  bentoWsWssUrl,
  sessionId,
  correlationId,
}: WebSocketProviderProps) => {
  const [connectionStatus, setConnectionStatus] =
    useState<WebSocketConnectionStatuses>("closed");

  const [subscribe, setSubscribe] = useState<WebSocketSubscribeFunc>(() =>
    Promise.resolve({})
  );

  const [subscribeSync, setSubscribeSync] =
    useState<WebSocketSubscribeSyncFunc>(() => "");

  const [publish, setPublish] = useState<WebSocketPublishFunc>(() =>
    Promise.resolve()
  );

  const [unsubscribe, setUnsubscribe] = useState<WebSocketUnsubscribeFunc>(() =>
    Promise.resolve({})
  );

  const [unsubscribeSync, setUnsubscribeSync] =
    useState<WebSocketUnsubscribeSyncFunc>(() => {});

  const value: WebSocketProviderContextProps = {
    connectionStatus,
    setConnectionStatus,
    implementation,
    subscribe,
    setSubscribe,
    subscribeSync,
    setSubscribeSync,
    publish,
    setPublish,
    unsubscribe,
    setUnsubscribe,
    unsubscribeSync,
    setUnsubscribeSync,
  };

  return (
    <WebSocketProviderContext.Provider value={value}>
      {implementation === "iot" ? (
        <IotProvider
          apiUrl={iotApiUrl}
          iotWssUrl={iotWssUrl}
          userId={userId}
          sessionId={sessionId}
          correlationId={correlationId}
          hasIotCredentialsCaching={HAS_IOT_CREDENTIALS_CACHING}
        >
          {children}
        </IotProvider>
      ) : implementation === "bento" ? (
        <BentoWSProvider
          apiUrl={bentoWsApiUrl}
          iotWssUrl={bentoWsWssUrl}
          userId={userId}
          sessionId={sessionId}
          correlationId={correlationId}
          hasIotCredentialsCaching={HAS_IOT_CREDENTIALS_CACHING}
        >
          {children}
        </BentoWSProvider>
      ) : (
        <div></div>
      )}
    </WebSocketProviderContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketProviderContext);

export class WebSocketAdapter {
  private _iotPublish: WebSocketPublishFunc;
  private _bentoWsPublish: WebSocketPublishFunc;
  private _iotSubscribe: WebSocketSubscribeFunc;
  private _bentoWsSubscribe: WebSocketSubscribeFunc;

  constructor(private implementation: WebSocketImplementation) {
    this._iotPublish = () => Promise.resolve();
    this._bentoWsPublish = () => Promise.resolve();

    this._iotSubscribe = () => Promise.resolve();
    this._bentoWsSubscribe = () => Promise.resolve();
  }

  get iotPublish() {
    return this._iotPublish;
  }

  set iotPublish(v: WebSocketPublishFunc | undefined) {
    if (v) {
      this._iotPublish = v;
    }
  }

  get bentoWsPublish() {
    return this._bentoWsPublish;
  }

  set bentoWsPublish(v: WebSocketPublishFunc | undefined) {
    if (v) {
      this._bentoWsPublish = v;
    }
  }

  get iotSubscribe() {
    return this._iotSubscribe;
  }

  set iotSubscribe(v: WebSocketSubscribeFunc | undefined) {
    if (v) {
      this._iotSubscribe = v;
    }
  }

  get bentoWsSubscribe() {
    return this._bentoWsSubscribe;
  }

  set bentoWsSubscribe(v: WebSocketSubscribeFunc | undefined) {
    if (v) {
      this._bentoWsSubscribe = v;
    }
  }

  async publish(topic: string, payload: unknown, correlationId?: string) {
    console.log(
      "[bento] use_websocket.tsx: WebSocketAdapter.publish(): Received: ",
      { topic, payload, correlationId, implementation: this.implementation }
    );

    if (this.implementation === "iot") {
      await this._iotPublish(topic, payload, correlationId);
      return;
    }

    if (this.implementation === "bento") {
      await this._bentoWsPublish(topic, payload, correlationId);
      return;
    }
  }

  async subscribe(
    topic: string,
    cb: (payload: unknown) => void,
    errCb?: (error: Error) => void,
    correlationId?: string
  ) {
    if (this.implementation === "iot") {
      const res = await this._iotSubscribe(topic, cb, errCb, correlationId);
      return res;
    }

    if (this.implementation === "bento") {
      const res = await this._bentoWsSubscribe(topic, cb, errCb, correlationId);
      return res;
    }
  }
}
