import BrowserTabMutex from "idb-mutex";
import { Mutex } from "async-mutex";

// Use this to prevent browser tabs and background scripts
// from doing specific activities simultaneously
// to avoid race conditions

const DEFAULT_LOCK_EXPIRY_MS = 10 * 1000;

export type LockerOptions = {
  lockExpiryMs?: number;
  isEnabled?: boolean;
  isLocal?: boolean;
  mutexObject?: any;
};

export interface IMutexLocker {
  lockThenRun: <T>(lockName: string, funcToRun: () => Promise<T>) => Promise<T>;
}

export const createLocker = (options?: LockerOptions): IMutexLocker => {
  if (options?.isLocal) {
    if (!options?.mutexObject) {
      throw new Error(
        "concurrency.ts: createLocker(): Mutex object expected if options.isLocal is true"
      );
    }

    return new LocalLocker(options);
  }

  return new DefaultLocker(options);
};

class DefaultLocker implements IMutexLocker {
  constructor(private options?: LockerOptions) {}

  public lockThenRun = async <T>(
    lockName: string,
    funcToRun: () => Promise<T>
  ): Promise<T> => {
    const isEnabled =
      !this.options ||
      this.options.isEnabled === undefined ||
      this.options.isEnabled;

    if (isEnabled) {
      console.log("[tako] concurrency.ts: Locking is enabled");
    } else {
      console.log("[tako] concurrency.ts: Locking is disabled");
    }

    const mutex = !isEnabled
      ? null
      : new BrowserTabMutex(lockName, null, {
          expiry: this.options?.lockExpiryMs ?? DEFAULT_LOCK_EXPIRY_MS,
        });

    try {
      if (mutex) {
        await mutex.lock();
      }

      const result = await funcToRun();
      return result;
    } finally {
      if (mutex) {
        await mutex.unlock();
      }
    }
  };
}

class LocalLocker implements IMutexLocker {
  constructor(private options?: LockerOptions) {}

  public lockThenRun = async <T>(
    lockName: string,
    funcToRun: () => Promise<T>
  ): Promise<T> => {
    if (
      !this.options?.mutexObject ||
      !(this.options?.mutexObject instanceof Mutex)
    ) {
      throw new Error("Invalid Mutex object provided");
    }

    const asyncMutex = this.options.mutexObject as Mutex;

    const ret = await asyncMutex.runExclusive(async () => {
      const result = await funcToRun();
      return result;
    });

    return ret;
  };
}

export type SharedRecordsCallback<T, V> = (
  records: Record<string, T>
) => Promise<V>;

export interface ISharedRecordsAccessor<T> {
  get: (key: string) => Promise<T | undefined>;
  set: (key: string, val: T) => Promise<void>;
  remove: (key: string) => Promise<void>;
  use: <V>(callback: SharedRecordsCallback<T, V>) => Promise<V>;
}

class SharedRecordsAccessor<T> implements ISharedRecordsAccessor<T> {
  constructor(
    private records: Record<string, T>,
    private mutex: Mutex
  ) {}

  async get(key: string): Promise<T | undefined> {
    const ret = await this.mutex.runExclusive(async () => {
      return this.records[key];
    });

    return ret;
  }

  async set(key: string, val: T): Promise<void> {
    await this.mutex.runExclusive(async () => {
      this.records[key] = val;
    });
  }

  async remove(key: string): Promise<void> {
    await this.mutex.runExclusive(async () => {
      delete this.records[key];
    });
  }

  async use<V>(callback: SharedRecordsCallback<T, V>): Promise<V> {
    const ret = await this.mutex.runExclusive(async () => {
      const result = await callback(this.records);

      return result;
    });

    return ret;
  }
}

export const createSharedRecordsAccessor = <T>(
  records: Record<string, T>,
  mutex: Mutex
) => new SharedRecordsAccessor(records, mutex);
