import awsIot from "aws-iot-device-sdk";
import { ulid } from "ulid";
import {
  AWSCredentials,
  DeviceCallback,
  DeviceConnectResponse,
  DeviceErrorCallback,
  DeviceMessageCallback,
  DeviceSetIdentityResponse,
  DeviceSubscribeResponse,
  DeviceSubscriptionGrant,
  DeviceUnsetIdentityResponse,
  DeviceUnsubscribeResponse,
} from "./common";
import { createLocker } from "./concurrency";

export type IoTConnectionStatuses =
  | "connected"
  | "reconnecting"
  | "closed"
  | "offline"
  | "pending";

export type IotDeviceOptions = {
  prefix?: string;
  hasIotCredentialsCaching?: boolean;
};

type CredsObject = {
  aws_region: string;
  aws_access_id: string;
  aws_secret_key: string;
  aws_sts_token: string;
  aws_identity_id: string;
  expiration: string; // ISO 8901 timestamp
  connectionId: string;
};

type GetCredentialsApiResponse = {
  connectionId: string;
  credentials: {
    identityId: string;
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken?: string;
    expiration?: string; // ISO 8901 timestamp
  };
};

const STORAGE_ITEMS = {
  ACCESS_ID: "tako0",
  SECRET_KEY: "tako1",
  STS_TOKEN: "tako2",
  IDENTITY_ID: "tako3",
};

const CREDS_EXPIRY_ALLOWANCE_MINS = 5;
const MAX_RETRIES_QUERY_GET_CREDENTIALS = 3;
const WAIT_MS_QUERY_GET_CREDENTIALS = 200;

const sleep = async (millis: number): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(resolve, millis);
  });

export class IotDevice {
  private credentials?: AWSCredentials & { connectionId?: string };
  private client?: awsIot.device;
  private clientId?: string;
  private userId?: string;
  private disconnectRequested: boolean;
  private handleReconnect?: () => void;
  private topicsMap: Record<
    string,
    Record<string, (payload: unknown) => void>
  > = {};
  private handleStatusChange: (status: IoTConnectionStatuses) => void =
    () => {};

  private hasReconnected = false;
  private prefix?: string;

  public status: IoTConnectionStatuses = "closed";

  constructor(
    private webSocketEndpointUrl: string,
    private apiEndpointUrl: string,
    private region: string,
    private sessionId?: string,
    private correlationId?: string,
    private iotDeviceOptions?: IotDeviceOptions
  ) {
    this.disconnectRequested = false;
    this.prefix = this.iotDeviceOptions?.prefix;

    console.info(
      `[tako] === iotdevice.ts: constructor: [${
        this.prefix ?? "default"
      }] hasIotCredentialsCaching: `,
      !!this.iotDeviceOptions?.hasIotCredentialsCaching
    );
  }

  public onStatusChange(cb: (status: IoTConnectionStatuses) => void) {
    this.handleStatusChange = cb;
  }

  public async connect(userId?: string): Promise<DeviceConnectResponse> {
    this.handleStatusChange("pending");
    const internalCorrelationId = ulid();
    if (!this.credentials || this.userId !== userId) {
      const gcr = await this.getCredentials(userId);
      if (!gcr) {
        throw new Error("[tako] Unable to obtain connection credentials");
      }

      this.credentials = gcr;
      this.clientId = this.credentials.connectionId;

      this.userId = userId;
    }

    if (!this.clientId) {
      throw new Error("[tako] Unable to obtain a connection ID");
    }

    if (this.disconnectRequested) {
      await this.disconnect();
      this.disconnectRequested = false;

      return {
        clientId: this.clientId,
        expiration: this.credentials.expiration ?? "",
      };
    }

    console.info(
      `[tako] === iotdevice.ts: connect: Connecting to ${this.webSocketEndpointUrl}`
    );
    const endpointUrl = new URL(this.webSocketEndpointUrl);

    const deviceOpts: awsIot.DeviceOptions = {
      protocol: "wss",
      clientId: this.clientId,
      host: endpointUrl.hostname,
      path: endpointUrl.pathname,
      accessKeyId: this.credentials.aws_access_id,
      secretKey: this.credentials.aws_secret_key,
      sessionToken: this.credentials.aws_sts_token,
      region: this.credentials.aws_region,
    };

    if (endpointUrl.port) {
      deviceOpts.port = +endpointUrl.port;
    }

    const client = await this.createClient(deviceOpts);

    // Automatically subscribe to pre-existing topics
    // await this.subscribe(this.subscribedTopics);

    client.on("close", this.onCloseHandler);
    client.on("error", this.onErrorHandler);
    client.on("offline", this.onOfflineHandler);
    client.on("reconnect", () => {
      this.onReconnectHandler();
      this.hasReconnected = true;
    });

    client.on("message", (topic, rawPayload) => {
      const payload = JSON.parse(rawPayload.toString());

      if (Array.isArray(payload)) {
        payload.forEach((p) => {
          if (this.topicsMap[topic]) {
            const funcs = Object.values(this.topicsMap[topic]);
            funcs.forEach((func) => {
              func(p);
            });
          } else {
            this.onMessageHandler(topic, p);
          }
        });

        return;
      } else if (this.topicsMap[topic]) {
        const funcs = Object.values(this.topicsMap[topic]);
        funcs.forEach((func) => {
          func(payload);
        });
      } else {
        this.onMessageHandler(topic, payload);
      }
    });

    this.client = client;

    await this.onConnectHandler();

    return {
      clientId: this.clientId,
      expiration: this.credentials.expiration ?? "",
    };
  }

  public async userPing() {
    const gcr = await this.getCredentials();
    if (!gcr) {
      throw new Error("[tako] Unable to obtain connection credentials");
    }

    this.credentials = gcr;
    console.info(
      `[tako] === iotdevice.ts: userPing: Connecting to ${this.webSocketEndpointUrl}`
    );
    const endpointUrl = new URL(this.webSocketEndpointUrl);
    this.clientId = this.credentials.connectionId;
    const deviceOpts: awsIot.DeviceOptions = {
      protocol: "wss",
      clientId: this.clientId,
      host: endpointUrl.hostname,
      path: endpointUrl.pathname,
      accessKeyId: this.credentials.aws_access_id,
      secretKey: this.credentials.aws_secret_key,
      sessionToken: this.credentials.aws_sts_token,
      region: this.credentials.aws_region,
    };
    const client = await this.createClient(deviceOpts);
    this.client = client;

    await this.onConnectHandler();

    return {
      clientId: this.clientId,
    };
  }

  public subscribeSync(
    topic: string,
    cb: (payload: unknown) => void,
    errCb?: (error: Error) => void
  ) {
    if (!this.client) return;

    const subscriptionId = ulid();

    this.client.subscribe(topic, undefined, (err) => {
      if (errCb && err) {
        errCb(err);
      }
    });

    if (this.topicsMap[topic]) {
      this.topicsMap = {
        ...this.topicsMap,
        [topic]: { ...this.topicsMap[topic], subscriptionId: cb },
      };
    } else {
      this.topicsMap = {
        ...this.topicsMap,
        [topic]: { subscriptionId: cb },
      };
    }

    return subscriptionId;
  }

  public async subscribe(
    topic: string,
    cb: (payload: unknown) => void,
    errCb?: (error: Error) => void
  ): Promise<DeviceSubscribeResponse> {
    const subscriptionId = ulid();
    const promise = new Promise<DeviceSubscribeResponse>((resolve, reject) => {
      if (!this.client) {
        resolve({ grants: [], subscriptionId });
      } else {
        this.client.subscribe(topic, undefined, (err, grants) => {
          if (err) {
            if (errCb) {
              errCb(err);
            }

            reject(err);
          } else {
            const res: DeviceSubscribeResponse = {
              grants: grants.map(
                (g) =>
                  ({ topic: g.topic, qos: g.qos }) as DeviceSubscriptionGrant
              ),
              subscriptionId,
            };

            resolve(res);
          }
        });
      }
    });

    const res = await promise;

    if (this.topicsMap[topic]) {
      this.topicsMap = {
        ...this.topicsMap,
        [topic]: { ...this.topicsMap[topic], subscriptionId: cb },
      };
    } else {
      this.topicsMap = {
        ...this.topicsMap,
        [topic]: { subscriptionId: cb },
      };
    }

    return res;
  }

  public unsubcribeSync(topic: string, subscriptionId?: string) {
    if (!this.client) return;

    if (subscriptionId) {
      delete this.topicsMap[topic][subscriptionId];
    } else {
      delete this.topicsMap[topic];
    }

    if (!subscriptionId) {
      this.client.unsubscribe(topic);
    }
  }

  public async unsubscribe(
    topic: string,
    subscriptionId?: string
  ): Promise<DeviceUnsubscribeResponse> {
    const promise = new Promise<DeviceUnsubscribeResponse>(
      (resolve, reject) => {
        if (!this.client) {
          resolve({ packet: {} });
        } else {
          this.client.unsubscribe(topic, (err, packet) => {
            if (err) {
              reject(err);
            } else {
              const res: DeviceUnsubscribeResponse = { packet };
              resolve(res);
            }
          });
        }
      }
    );

    const res = await promise;

    if (subscriptionId) {
      delete this.topicsMap[topic][subscriptionId];
    } else {
      delete this.topicsMap[topic];
    }

    return res;
  }

  public async publish(topic: string, message: unknown): Promise<void> {
    const msg = JSON.stringify(message);

    return new Promise((resolve, reject) => {
      if (!this.client) {
        throw new Error("[tako] Device not connected");
      } else {
        this.client.publish(topic, msg, undefined, (err) => {
          if (err) {
            console.error("[tako] iotdevice.ts: publish", err);
            reject(err);
          } else {
            resolve();
          }
        });
      }
    });
  }

  public async setIdentity(
    idp: string,
    token: string
  ): Promise<DeviceSetIdentityResponse> {
    const gcr = await this.getCredentials();
    if (!gcr) {
      throw new Error("[tako] Unable to obtain connection credentials");
    }

    this.credentials = gcr;
    this.clientId = this.credentials.connectionId;

    if (!this.clientId) {
      throw new Error(
        "[tako] Unable to obtain a connection ID while setting identity"
      );
    }

    return {
      clientId: this.clientId,
    };
  }

  public async unsetIdentity(): Promise<DeviceUnsetIdentityResponse> {
    let gcr: CredsObject | null;

    try {
      gcr = await this.getCredentials();
    } catch (err: any) {
      // Try again
      gcr = await this.getCredentials();
    }

    if (!gcr) {
      throw new Error("[tako] Unable to obtain connection credentials");
    }

    this.credentials = gcr;
    this.clientId = this.credentials.connectionId;

    if (!this.clientId) {
      throw new Error(
        "[tako] Unable to obtain a connection ID while unsetting identity"
      );
    }

    return {
      clientId: this.clientId,
    };
  }

  public async disconnect(): Promise<void> {
    this.disconnectRequested = true;

    return new Promise((resolve) => {
      if (this.client) {
        this.client.end(true, () => {
          this.disconnectRequested = false;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  public isConnected(): boolean {
    return !!this.client;
  }

  public setOnConnect(listener: DeviceCallback) {
    this.onConnectHandler = listener;
  }

  public setOnClose(listener: DeviceCallback) {
    this.onCloseHandler = listener;
  }

  public onReconnect(listener: DeviceCallback) {
    this.handleReconnect = listener;
  }

  public setOnOffline(listener: DeviceCallback) {
    this.onOfflineHandler = listener;
  }

  public setOnError(listener: DeviceErrorCallback) {
    this.onErrorHandler = listener;
  }

  public setOnMessage(listener: DeviceMessageCallback) {
    this.onMessageHandler = listener;
  }

  private credentialsExpired(storedCreds: GetCredentialsApiResponse): boolean {
    if (!storedCreds.credentials.expiration) {
      return false;
    }

    const now = Date.now();

    const expirationMillis = new Date(
      storedCreds.credentials.expiration
    ).getTime();

    return expirationMillis - now < CREDS_EXPIRY_ALLOWANCE_MINS * 60 * 1000;
  }

  private async refreshCredentials(userId?: string) {
    console.info(
      `[tako] === iotdevice.ts: refreshCredentials: [${
        this.prefix ?? "default"
      }] Refreshing with previous connection id: ${this.clientId}: `
    );

    const gcr = await this.getCredentials(userId, true, this.clientId);
    if (!gcr) {
      throw new Error("[tako] Unable to refresh connection credentials");
    }

    this.credentials = gcr;
    this.clientId = this.credentials.connectionId;

    console.info(
      `[tako] === iotdevice.ts: refreshCredentials: [${
        this.prefix ?? "default"
      }] Done refreshing`
    );
  }

  private async setRefreshTimer(creds: CredsObject, userId?: string) {
    const hasIotCredentialsCaching =
      !!this.iotDeviceOptions?.hasIotCredentialsCaching;

    if (creds.expiration && hasIotCredentialsCaching) {
      const expirationMillis = new Date(creds.expiration).getTime();

      const now = Date.now();

      let timeLeft =
        expirationMillis - now - CREDS_EXPIRY_ALLOWANCE_MINS * 60 * 1000;

      if (timeLeft < 0) {
        timeLeft = 0;
      }

      console.info(
        `[tako] === iodevice.ts: setRefreshTimer: [${
          this.prefix ?? "default"
        }] Setting timer, time left: ${timeLeft} ms`
      );

      const locker = createLocker({ isEnabled: hasIotCredentialsCaching });
      const lockName = this.makeItemKey("tako-timer-lock");

      const timerKey = this.makeItemKey("takoTimerId");

      await locker.lockThenRun(lockName, async () => {
        let timerId = JSON.parse(sessionStorage.getItem(timerKey) ?? "0");

        console.info(
          `[tako] === iodevice.ts: setRefreshTimer: [${
            this.prefix ?? "default"
          }] Existing timerId: ${timerId}`
        );

        if (timerId) {
          console.info(
            `[tako] === iodevice.ts: setRefreshTimer: [${
              this.prefix ?? "default"
            }] Canceling existing timer`
          );

          clearTimeout(timerId);
        }

        timerId = setTimeout(() => this.refreshCredentials(userId), timeLeft);

        sessionStorage.setItem(timerKey, JSON.stringify(timerId));
      });
    } else {
      console.info(
        `[tako] === iodevice.ts: setRefreshTimer: [${
          this.prefix ?? "default"
        }] Not setting timer`
      );
    }
  }

  private async getCredentials(
    userId?: string,
    forced?: boolean,
    prevConnectionId?: string
  ): Promise<CredsObject | null> {
    const hasIotCredentialsCaching =
      !!this.iotDeviceOptions?.hasIotCredentialsCaching;

    const locker = createLocker({ isEnabled: hasIotCredentialsCaching });

    const lockName = this.makeItemKey("tako-lock");

    if (hasIotCredentialsCaching) {
      console.info(
        `[tako] === iodevice.ts: getCredentials: [${
          this.prefix ?? "default"
        }] Initiating lock: `,
        lockName
      );
    }

    const result = await locker.lockThenRun(lockName, async () => {
      const storedCreds = await this.retrieveState(userId);

      if (
        hasIotCredentialsCaching &&
        !forced &&
        !this.credentialsExpired(storedCreds) &&
        storedCreds.credentials.accessKeyId &&
        storedCreds.credentials.secretAccessKey &&
        storedCreds.credentials.sessionToken &&
        storedCreds.connectionId
      ) {
        console.info(
          `[tako] === iodevice.ts: getCredentials: [${
            this.prefix ?? "default"
          }] Using stored state, not invoking API`
        );

        const creds: CredsObject = {
          aws_region: this.region,
          aws_access_id: storedCreds.credentials.accessKeyId,
          aws_secret_key: storedCreds.credentials.secretAccessKey,
          aws_sts_token: storedCreds.credentials.sessionToken ?? "",
          aws_identity_id: storedCreds.credentials.identityId,
          expiration: storedCreds.credentials.expiration ?? "",
          connectionId: storedCreds.connectionId,
        };

        await this.setRefreshTimer(creds, userId);

        return creds;
      }

      console.info(
        `[tako] === iodevice.ts: getCredentials: [${
          this.prefix ?? "default"
        }] No stored state, credentials expired, or forced, invoking API...`
      );

      let CREDS_URL = `${this.apiEndpointUrl}/get_credentials`;
      let hasParams = false;
      const q = new URLSearchParams();

      if (userId) {
        q.append("user_id", userId);
        hasParams = true;
      }

      if (this.sessionId) {
        q.append("session_id", this.sessionId);
        hasParams = true;
      }

      if (this.correlationId) {
        q.append("correlation_id", this.correlationId);
        hasParams = true;
      }

      if (prevConnectionId) {
        q.append("prev_conn_id", prevConnectionId);
        hasParams = true;
      }

      if (hasParams) {
        CREDS_URL = `${CREDS_URL}?${q.toString()}`;
      }

      const retryCount = MAX_RETRIES_QUERY_GET_CREDENTIALS;
      const waitBaseDurationMs = WAIT_MS_QUERY_GET_CREDENTIALS;

      let i = 0;
      let waitDuration = waitBaseDurationMs;
      let resp: Response | null = null;

      while (i <= retryCount) {
        resp = await fetch(CREDS_URL, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (resp.ok || !hasIotCredentialsCaching) {
          break;
        }

        if (resp.status === 503 || resp.status === 429) {
          // Service Unavailable/Too Many Request
          ++i;

          if (i > retryCount) {
            console.info(
              `[tako] === iodevice.ts: getCredentials: [${
                this.prefix ?? "default"
              }] Max retry count ${retryCount} already exceeded, giving up`
            );

            break;
          }

          console.info(
            `[tako] === iodevice.ts: getCredentials: [${
              this.prefix ?? "default"
            }] Retrying operation, retry count = ${i}, max = ${retryCount}, status was ${
              resp.status
            } - ${resp.statusText}`
          );

          await sleep(waitDuration);
          waitDuration = waitDuration * 2; // Exponential wait
        } else {
          // Do not retry on other errors
          console.info(
            `[tako] === iodevice.ts: getCredentials: [${
              this.prefix ?? "default"
            }] Got error, not retrying: ${resp.status} - ${resp.statusText}`
          );

          break;
        }
      }

      if (!resp) {
        throw new Error(
          `[tako] iodevice.ts: getCredentials: [${
            this.prefix ?? "default"
          }] Unable to retrieve credentials`
        );
      }

      if (resp.ok) {
        const result = (await resp.json()) as GetCredentialsApiResponse;

        const creds: CredsObject = {
          aws_region: this.region,
          aws_access_id: result.credentials.accessKeyId,
          aws_secret_key: result.credentials.secretAccessKey,
          aws_sts_token: result.credentials.sessionToken ?? "",
          aws_identity_id: result.credentials.identityId,
          expiration: result.credentials.expiration ?? "",
          connectionId: result.connectionId,
        };

        await this.storeState(result, userId);

        await this.setRefreshTimer(creds, userId);

        return creds;
      }

      return null;
    });

    if (hasIotCredentialsCaching) {
      console.info(
        `[tako] === iodevice.ts: getCredentials: [${
          this.prefix ?? "default"
        }] Releasing lock: `,
        lockName
      );
    }

    return result;
  }

  private obf(text: string) {
    const textBin = new TextEncoder().encode(text);
    const buff = new Uint8Array(textBin.length + 1);

    const mask = Math.floor(Math.random() * 256);
    buff[0] = mask;
    for (let i = textBin.length - 1, j = 1; i >= 0; i--, j++) {
      buff[j] = textBin[i] ^ mask;
    }

    const base64 = btoa(
      String.fromCharCode.apply(null, buff as unknown as number[])
    );

    return base64;
  }

  private deObf(obData: string) {
    // obData is expected to be in base64
    const base64 = obData;

    try {
      const buff = new Uint8Array(
        [...atob(base64)].map((c) => c.charCodeAt(0))
      );

      const mask = buff[0];
      const textBin = new Uint8Array(buff.length - 1);

      for (let i = buff.length - 1, j = 0; i >= 1; i--, j++) {
        textBin[j] = buff[i] ^ mask;
      }

      const text = new TextDecoder().decode(textBin);

      return text;
    } catch (err: any) {
      return "";
    }
  }

  private makeItemKey(itemName: string, userId?: string) {
    const prefixedKey = this.prefix ? `${this.prefix}:${itemName}` : itemName;

    return !userId ? prefixedKey : `${prefixedKey}:u`;
  }

  private async storeState(data: GetCredentialsApiResponse, userId?: string) {
    localStorage.setItem("takoClientId", data.connectionId);

    if (!this.iotDeviceOptions?.hasIotCredentialsCaching) return;

    console.info(
      `[tako] === iodevice.ts: storeState: [${
        this.prefix ?? "default"
      }] Storing new state...`
    );

    sessionStorage.setItem(
      this.makeItemKey("takoExpiresAt"),
      JSON.stringify(
        data.credentials.expiration
          ? new Date(data.credentials.expiration).getTime()
          : 0
      )
    );

    sessionStorage.setItem(
      this.makeItemKey(STORAGE_ITEMS.ACCESS_ID),
      JSON.stringify(this.obf(data.credentials.accessKeyId))
    );

    sessionStorage.setItem(
      this.makeItemKey(STORAGE_ITEMS.SECRET_KEY),
      JSON.stringify(this.obf(data.credentials.secretAccessKey))
    );

    sessionStorage.setItem(
      this.makeItemKey(STORAGE_ITEMS.STS_TOKEN),
      JSON.stringify(data.credentials.sessionToken ?? "")
    );

    sessionStorage.setItem(
      this.makeItemKey(STORAGE_ITEMS.IDENTITY_ID),
      JSON.stringify(this.obf(data.credentials.identityId))
    );
  }

  private async retrieveState(
    userId?: string
  ): Promise<GetCredentialsApiResponse> {
    const connectionId = localStorage.getItem("takoClientId") ?? "";

    if (!this.iotDeviceOptions?.hasIotCredentialsCaching)
      return {
        connectionId: "",
        credentials: {
          accessKeyId: "",
          identityId: "",
          secretAccessKey: "",
        },
      } as GetCredentialsApiResponse;

    console.info(
      `[tako] === iodevice.ts: retrieveState: [${
        this.prefix ?? "default"
      }] Retrieving state...`
    );

    const exp = JSON.parse(
      sessionStorage.getItem(this.makeItemKey("takoExpiresAt")) ?? "0"
    );

    let expiration = "";
    if (!isNaN(exp) && exp > 0) {
      expiration = new Date(exp).toISOString();
    }

    const accessKeyId = this.deObf(
      JSON.parse(
        sessionStorage.getItem(this.makeItemKey(STORAGE_ITEMS.ACCESS_ID)) ??
          '""'
      )
    );

    const secretAccessKey = this.deObf(
      JSON.parse(
        sessionStorage.getItem(this.makeItemKey(STORAGE_ITEMS.SECRET_KEY)) ??
          '""'
      )
    );

    const sessionToken = JSON.parse(
      sessionStorage.getItem(this.makeItemKey(STORAGE_ITEMS.STS_TOKEN)) ?? '""'
    );

    const identityId = this.deObf(
      JSON.parse(
        sessionStorage.getItem(this.makeItemKey(STORAGE_ITEMS.IDENTITY_ID)) ??
          '""'
      )
    );

    const ret: GetCredentialsApiResponse = {
      connectionId: connectionId,
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
        expiration,
        identityId,
      },
    };

    return ret;
  }

  private async createClient(
    deviceOpts: awsIot.DeviceOptions
  ): Promise<awsIot.device> {
    this.status = "pending";
    const promise = new Promise<awsIot.device>((resolve, reject) => {
      try {
        const device = new awsIot.device(deviceOpts);
        device.on("close", () => {});
        device.on("connect", () => {
          if (this.hasReconnected) {
            this.handleStatusChange("connected");
            this.hasReconnected = false;
          }

          resolve(device);
        });
      } catch (err: any) {
        reject(err);
      }
    });

    return promise;
  }

  private onConnectHandler: DeviceCallback = async () => {
    await this.handleStatusChange("connected");
  };

  private onCloseHandler: DeviceCallback = async () => {
    await this.handleStatusChange("closed");
  };

  private onReconnectHandler: DeviceCallback = async () => {
    await this.handleStatusChange("reconnecting");
  };

  private onOfflineHandler: DeviceCallback = async () => {
    await this.handleStatusChange("offline");
  };

  private onErrorHandler: DeviceErrorCallback = async (e) => {};

  private onMessageHandler: DeviceMessageCallback = async (
    topic,
    payload
  ) => {};
}
