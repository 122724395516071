import { createContext, ReactNode, useContext } from "react";

export type ExperimentalFeatures = {
  hasUserAgreementModal?: boolean;
  hasPausedUsersEnabled?: boolean;
  hasReactionsEnabled?: boolean;
  hasInteractivityEnabled?: boolean;
  hasRealtimeMetricsEnabled?: boolean;
  hasReportsEnabled?: boolean;
  hasAuditPageEnabled?: boolean;
  hasFavoriteEnabled?: boolean;
  hasArchivingEnabled?: boolean;
  hasLiveMetric?: boolean;
  hasLiveVotingMetricsEnabled?: boolean;
  hasThemeManagerEnabled?: boolean;
  hasMarketingManagerEnabled?: boolean;
  hasAdsModuleEnabled?: boolean;
  hasMultpleReportsEnabled?: boolean;
  hasMultpleReportsWithSceneEnabled?: boolean;
  hasAdsReportsEnabled?: boolean;
  hasInteractivityReportsEnabled?: boolean;
  hasConfettiEnabled?: boolean;
  hasRaffleEntriesEnabled?: boolean;
  hasBentoWSEnabled?: boolean;
};

export type FeatureFlagProviderProps = {
  children: ReactNode;
};

export const FeatureFlagContext = createContext<ExperimentalFeatures>({});

export const isEnabled = (feature: string) =>
  !!feature && feature.toLocaleLowerCase() === "true";

export function FeatureFlagProvider(props: FeatureFlagProviderProps) {
  const value = {
    hasUserAgreementModal: isEnabled(
      import.meta.env.VITE_HAS_USER_AGREEMENT_MODAL
    ),
    hasPausedUsersEnabled: isEnabled(
      import.meta.env.VITE_HAS_PAUSED_USERS_ENABLED
    ),
    hasReactionsEnabled: isEnabled(import.meta.env.VITE_HAS_REACTIONS_ENABLED),
    hasInteractivityEnabled: isEnabled(
      import.meta.env.VITE_HAS_INTERACTIVITY_ENABLED
    ),
    hasRealtimeMetricsEnabled: isEnabled(
      import.meta.env.VITE_HAS_REALTIME_METRICS_ENABLED
    ),
    hasReportsEnabled: isEnabled(import.meta.env.VITE_HAS_REPORTS_ENABLED),
    hasMultpleReportsEnabled: isEnabled(
      import.meta.env.VITE_HAS_MULTPLE_REPORTS_ENABLED
    ),
    hasMultpleReportsWithSceneEnabled: isEnabled(
      import.meta.env.VITE_HAS_MULTPLE_REPORTS_WITHSCENE_ENABLED
    ),
    hasAuditPageEnabled: isEnabled(import.meta.env.VITE_HAS_AUDIT_PAGE_ENABLED),
    hasFavoriteEnabled: isEnabled(import.meta.env.VITE_HAS_FAVORITE_ENABLED),
    hasArchivingEnabled: isEnabled(import.meta.env.VITE_HAS_ARCHIVING_ENABLED),
    hasLiveMetric: isEnabled(import.meta.env.VITE_HAS_LIVE_METRICS_ENABLED),
    hasMarketingManagerEnabled: isEnabled(
      import.meta.env.VITE_HAS_MARKETING_MANAGER_ENABLED
    ),
    hasAdsModuleEnabled: isEnabled(import.meta.env.VITE_HAS_ADS_MODULE_ENABLED),
    hasLiveVotingMetricsEnabled: isEnabled(
      import.meta.env.VITE_HAS_LIVE_VOTING_METRICS_ENABLED
    ),
    hasThemeManagerEnabled: isEnabled(
      import.meta.env.VITE_HAS_THEME_MANAGER_ENABLED
    ),
    hasAdsReportsEnabled: isEnabled(
      import.meta.env.VITE_HAS_ADS_REPORTS_ENABLED
    ),
    hasInteractivityReportsEnabled: isEnabled(
      import.meta.env.VITE_HAS_INTERACTIVITY_REPORTS_ENABLED
    ),
    hasConfettiEnabled: isEnabled(import.meta.env.VITE_HAS_CONFETTI_ENABLED),
    hasRaffleEntriesEnabled: isEnabled(
      import.meta.env.VITE_HAS_RAFFLE_ENTRIES_ENABLED
    ),
    hasBentoWSEnabled: isEnabled(
      import.meta.env.VITE_HAS_BENTO_WS_ENABLED
    ),
  };

  return (
    <FeatureFlagContext.Provider value={value}>
      {props.children}
    </FeatureFlagContext.Provider>
  );
}

export function useFeatureFlag() {
  const values = useContext(FeatureFlagContext);

  return values;
}
